import React from 'react';
import './ProjectCard.css';

const ProjectCard = ({ project }) => {
    return (
        <div className="project-card">
            <h2 className="project-name">{project.name}</h2>
            <div className="card-header">
                <img src={project.banner} alt={project.name} className="project-banner" />
            </div>
            <div className="card-body">
                <p className="project-description">{project.description}</p>
                <div className="project-details">
                    <p className='meta-details'><strong>Status:</strong> {project.status}</p>
                    <p className='meta-details'><strong>Technologies:</strong> {project.technologies.join(', ')}</p>
                </div>
                <div className="project-links">
                    {project.demoUrl && <a href={project.demoUrl} className="btn btn-primary">Demo</a>}
                    {project.githubRepoUrl && <a href={project.githubRepoUrl} className="btn btn-secondary">GitHub Repo</a>}
                </div>
            </div>
        </div>
    );
};

export default ProjectCard;
