export const getJWTToken = () => {
    const cookies = document.cookie.split('; ');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        if (cookie.startsWith("jwtAccessToken=")) {
            return cookie.split('=')[1];
        }
    }
    return "";
}


export const useAuth = () =>{
    const user = getJWTToken()
    if(user){
        return true
    }else{
        return false
    }
}
