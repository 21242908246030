import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faArrowUp,faHeart } from '@fortawesome/free-solid-svg-icons';
import './Footer.css';

function Footer() {
 
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer" style={{ backgroundColor: '#007bff', color: '#fff' }}>
      <div className="footer-content">
        <div className="made-with-love">
          Made with <FontAwesomeIcon icon={faHeart} style={{ color: 'red' }} /> by Jumale Abdi
        </div>
        <div className="social-media-links">
          <a href="https://github.com/jamalabdi2" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGithub} className="icon" />
          </a>
          <a href="https://twitter.com/juabji23" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} className="icon" />
          </a>
          <a href="https://www.linkedin.com/in/abdi-jumale-445010252/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} className="icon" />
          </a>
        </div>
        <div className="copyright">
          &copy; {currentYear} Jumale Abdi. All Rights Reserved.
        </div>
        <button className="back-to-top" onClick={scrollToTop}>
          <FontAwesomeIcon icon={faArrowUp} />
        </button>
      </div>
    </footer>
  );
}

export default Footer;
