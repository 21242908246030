import React, { useState } from 'react';
import './Register.css';
import { CREATE_USER_API_URL } from '../Config/Environmental_variables';
import {getJWTToken} from '../utils/useAuth'
import {useNavigate} from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Register() { 
  const navigate = useNavigate()
  const token = getJWTToken()
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(CREATE_USER_API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(formData)
      });
      const data = await response.json();
      if(response.ok){
        const previousUrls = document.referrer.split("/")
        const previousUrl = previousUrls[previousUrls.length - 1]
        if(previousUrl === 'admin'){
          navigate(-1)
        }
        toast.info('User created Successfully.')
      }else{
        toast.error(data.message)
      }
      
    } catch (error) {
      toast.error(`Error registering user \n ${error.message}`)
    }
  };

  return (
    <div className="register-container">
      <h2>Register</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Name:</label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input type="password" name="password" value={formData.password} onChange={handleChange} />
        </div>
        <button type="submit">Register</button>
      </form>
    </div>
  );
}

export default Register;
