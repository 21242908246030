// tokenInterceptor.js
import { getJWTToken } from './useAuth';
import { REFRESH_API_ENDPOINT } from '../Config/Environmental_variables';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TOKEN_REFRESH_INTERVAL = 11 * 60 * 60 * 1000  * 60 * 1000; // 11 hours

// Function to perform silent login and refresh access token
const silentLogin = async () => {
    try {
        const accessTokenCookie = getJWTToken();
        if (!accessTokenCookie) {
            toast.error('Access token is missing')
            window.location.href = '/login'
        }

        const response = await fetch(REFRESH_API_ENDPOINT, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${accessTokenCookie}`
            }
        });

        if(response.status === 403 || response.status === 401){
            toast.error('refresh token expired, login in again')
            window.location.href = '/login'
        } 
        
    } catch (error) {
        toast.error(`Error during silent login ${error.message}`)
    } finally {
        // Schedule the next refresh after TOKEN_REFRESH_INTERVAL milliseconds
        setTimeout(silentLogin, TOKEN_REFRESH_INTERVAL);
    }
};

// Start the initial token refresh
const tokenInterceptor = () => {
    silentLogin();
};

export default tokenInterceptor;


