import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faJs, faPython, faHtml5, faCss3Alt, faReact, faJava,faPhp,faDocker,faAws } from '@fortawesome/free-brands-svg-icons';
import {faArrowUp,faBrain,faImage} from '@fortawesome/free-solid-svg-icons'
import './Skills.css'
function Skills() {
    // Define an array of skills with their respective icons
    const skills = [
        { name: 'JavaScript', icon: faJs },
        { name: 'Python', icon: faPython },
        { name: 'Java', icon: faJava },
        { name: 'PHP', icon: faPhp },
        { name: 'React', icon: faReact },
        { name: 'HTML5', icon: faHtml5 },
        { name: 'CSS3', icon: faCss3Alt },
        { name: 'Docker', icon: faDocker },
        { name: 'Image Processing', icon: faImage },
        { name: 'Deep Learning', icon: faBrain },
        { name: 'Tensorflow', icon: faArrowUp},
        { name: 'AWS', icon: faAws },
        
    ];

    return (
        <div className='skillsContainer'>
            <h1 className='skillTitle'>Skills</h1>
            <div className="tag-container">
                {skills.map((skill, index) => (
                    <div key={index} className="tag">
                        <FontAwesomeIcon icon={skill.icon} style={{ color: 'white' , margin: '5px'}} />
                        <span className="skill-name">{skill.name}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Skills;
