import React from 'react';
import './Connect.css';

function Connect() {
  const handleEmailClick = () => {
    window.location.href = 'mailto:abdijumale2@gmail.com';
  };

  const handleLinkedInClick = () => {
    window.open('https://www.linkedin.com/in/abdi-jumale-445010252/', '_blank');
  };

  return (
    <div className='connectContainer'> 
        <h3>Let's Connect</h3>
        <p>If you'd like to get in touch, feel free to reach out to me:</p>
        <ul>
          <li><button className="contactButton" onClick={handleEmailClick}>Send me an email</button></li>
          <li><button className="contactButton" onClick={handleLinkedInClick}>Connect on LinkedIn</button></li>
        </ul>
    </div>
  );
}

export default Connect;
