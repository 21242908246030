import Navbar from './Components/Navbar';
import About from "./Pages/About"
import Skills from "./Pages/Skills"
import Projects from "./Pages/Projects"
import { Routes, Route } from 'react-router-dom'
import CreateProjects from './Components/CreateProjects';
import Blogs from './Pages/Blogs'
import RegisterUser from './Components/RegisterUser';
import Login from './Components/Login';
import Admin from './Components/Admin'
import NotFound404Page from './Pages/NotFoundPage';
import PrivateRoutes from './utils/privateRoute'

import "./App.css"

function App() {
  return (
    <div className="App">
      <Navbar/>
      <div className="container">
        <Routes>
          {/* public routes */}
            <Route index element={<About/>} />
            <Route path='/skills' element={<Skills/>} />
            <Route path='/projects' element={<Projects/>} />
            <Route path='/blogs' element={<Blogs/>} />
            <Route path='/login' element={<Login/>} />
            
          {/* private routes */}
          <Route element={<PrivateRoutes/>}>
            <Route path='/register' element={<RegisterUser/>} />
            <Route path='/createProject' element={<CreateProjects/>} />
            <Route path='/admin' element={<Admin/>} />
          </Route>

          <Route path='*' element={<NotFound404Page requestedUrl={window.location.href}/>}/>
        </Routes>
      </div>
    </div>
  );
}

export default App;
