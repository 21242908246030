import React, { useState } from 'react';
import './CreateProject.css'; 
import {CREATE_PROJECT_API_ENDPOINT} from '../Config/Environmental_variables'
import { toast } from 'react-toastify';
import {getJWTToken} from '../utils/useAuth'
import {useNavigate} from 'react-router-dom'
function CreateProjects() {
  const navigate = useNavigate()
  const [projectData, setProjectData] = useState({
    name: '',
    description: '',
    technologies: [],
    otherTechnology: '', 
    banner: '',
    status: 'ongoing',
    tags: [],
    githubRepoUrl: '',
    demoUrl: ''
  });

 
  const technologies = [
    "Python",
    "React",
    "MongoDB",
    "Javascript",
    "Java",
    "Express Js",
    "Django",
    "Flask",
    "SpringBoot",
    "Docker",
    "AWS",
    "Postgress",
    "HTML",
    "CSS",
    "Tensorflow",
    "Pytorch",
    "Php",
    "Mysql"
  ];

 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectData({ ...projectData, [name]: value });
  };

  // Function to handle file upload for the banner
  const handleBannerUpload = (e) => {
    const file = e.target.files[0];
    setProjectData({ ...projectData, banner: file});
  };

  // Function to handle selection of multiple technologies
  const handleTechnologySelection = (e) => {
    const { value, checked } = e.target;
    const updatedTechnologies = checked
      ? [...projectData.technologies, value]
      : projectData.technologies.filter((tech) => tech !== value);
    setProjectData({ ...projectData, technologies: updatedTechnologies });
  };
  const handleTags = (e) =>{
    const {name,value} = e.target
    const tagArray = value.split(',')
    setProjectData({...projectData,[name]: tagArray})
  }

  const handleAddOtherTechnology = () =>{
    const {otherTechnology} = projectData
    if(otherTechnology.trim() !== ''){
      setProjectData({
        ...projectData,
        technologies: [...projectData.technologies,otherTechnology],
        otherTechnology: ''
      })
    }
  }

  // Function to handle submission of the form
  
  const handleSubmit = async(e) => {
    e.preventDefault();
    const formData = new FormData()
    Object.entries(projectData).forEach(([key,value]) =>{
      if(key === 'banner'){
        formData.append(key,value)

      }else if (Array.isArray(value)){
        value.forEach((item) =>{
          formData.append(key,item)
        })
      }else{
        formData.append(key,value)
      }
    })
    const token  = getJWTToken()
    try {
      const response = await fetch(CREATE_PROJECT_API_ENDPOINT,{
        method: 'POST',
        body: formData,
        headers: {
          'Authorization':`Bearer ${token}`
        }
      })
      const responseData = await response.json()
      if(!response.ok){
        const errorMessage = responseData.message
        toast.error(errorMessage)
        throw new Error("Failed to create project")

      }
      
      setProjectData({
        name: '',
        description: '',
        technologies: [],
        otherTechnology: '',
        banner: '',
        status: 'ongoing',
        tags: [],
        githubRepoUrl: '',
        demoUrl: ''
      });
      const previousUrls = document.referrer.split("/")
      const previousUrl = previousUrls[previousUrls.length - 1]
      if(previousUrl === 'admin'){
        navigate(-1)
      }


    } catch (error) {
      toast.error(error.message)
      
    }
    
  };

  return (
    <div className="create-project-container">
      <h2 className='createProjectTitle'>Create New Project</h2>

      <form className='projectForm' onSubmit={handleSubmit} encType='multipart/form-data'>
        
          <input type="text" name="name" placeholder="Project Name" value={projectData.name} onChange={handleChange} />
          <textarea name="description" placeholder="Project Description" value={projectData.description} onChange={handleChange} />
     
          {/* Checkboxes for selecting multiple technologies */}
          <label>Select Technologies:</label><br />
          <div className='technologiesCheckbox'>
            <div className="technologies">
                {technologies.map((tech) => (
                  <div key={tech} className='technology'>
                    <input type="checkbox" name="technologies" value={tech} onChange={handleTechnologySelection} /> {tech}<br />
                  </div>
                ))}
            </div>
            
            <div className="otherTechnology">
              {/* Input field for adding other technology */}
               <div className="">
                  <input type="text" name="otherTechnology" placeholder="Other Technology" value={projectData.otherTechnology} onChange={handleChange} />
               </div>
               <div className="">
                  <button type="button" onClick={handleAddOtherTechnology}>Add Other Technology</button>
               </div>
                
            </div>
          </div>
          
          {/* File input for uploading banner */}
          <input type="file" name="banner" onChange={handleBannerUpload} />
        
          <select name="status" value={projectData.status} onChange={handleChange}>
            <option value="ongoing">Ongoing</option>
            <option value="completed">Completed</option>
            <option value="on hold">On Hold</option>
          </select>
          {/* Input field for entering multiple tags */}
          <input type="text" name="tags" placeholder="Tags (separated by commas)" value={projectData.tags} onChange={handleTags} />
          <input type="text" name="githubRepoUrl" placeholder="Github Repo URL" value={projectData.githubRepoUrl} onChange={handleChange} />
          <input type="text" name="demoUrl" placeholder="Demo URL" value={projectData.demoUrl} onChange={handleChange} />
          {/* Submit button */}
          <button type="submit">Create Project</button>
      </form>
    </div>
  );
}

export default CreateProjects;
