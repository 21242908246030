import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import {NODE_ENV} from './Config/Environmental_variables'

if(NODE_ENV === 'production'){
  disableReactDevTools()
}
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
        <ToastContainer />
        <App />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
