import React, { useEffect } from 'react';
import Logout from './Logout';
import tokenInterceptor from '../utils/tokenInterceptor';


import './Admin.css';
function Admin() {
 
  useEffect(() => {
    tokenInterceptor();
  }, []);


  return (
    <div className="admin-container">
      <h3 className="admin-title">Admin Page</h3>
      <Logout />
      <div className="admin-links">
        <a className="admin-link" href="/createProject">Create New Project</a>
        <a className="admin-link" href="/register">Register New User</a>
      </div>
      
    </div>
  );
}

export default Admin;
