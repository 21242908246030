import React from 'react'
function Blogs() {
  return (
    <div>
        <h3>Oops No blogs!</h3>
        <p>I will work on it </p>
      
    </div>
  )
}

export default Blogs