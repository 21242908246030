import React, { useState, useEffect } from 'react';
import ProjectCard from '../Components/ProjectCard';
import {FETCH_PROJECTS_API_ENDPOINT} from '../Config/Environmental_variables'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Projects() {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        async function fetchProjects() {
            try {
                const response = await fetch(FETCH_PROJECTS_API_ENDPOINT);
                if (!response.ok) {
                    toast.error(`Failed to fetch projects ${response.message}`)
                }
                const data = await response.json();
                setProjects(data.data);
            } catch (error) {
                toast.error(`Error fetching projects \n ${error.message}`)
            }
        }
        fetchProjects();
    }, []); 

    return (
        <div>
            <h1 className='projectTitle'>Projects</h1>
            <div className="projects-container">
                {projects.map((project, index) => (
                    <ProjectCard key={index} project={project} />
                ))}
            </div>
        </div>
    );
}

export default Projects;
