import React from 'react';
import mypic from "../assets/images/mypic.jpg";
import Skills from './Skills';
import Projects from './Projects';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './About.css';
import Footer from './Footer'
import Connect from './Connect'

function About() {

  const handleEmailClick = () => {
    window.location.href = 'mailto:abdijumale2@gmail.com';
  };

  return (
    <div className="about-container">
      <div className="imgContainer">
        <img className="myprofilepic" src={mypic} alt="Jumale Abdi Profile" />
      </div>
      <div className="textContainer">
        <h1 className="intro">Hi, I'm Jumale Abdi</h1>
        <h1 className="intro">I love creating </h1>
        <h1 className='intro'>and I like to code.</h1>
        <button className="contactButtonAbout" onClick={handleEmailClick}>
          <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '5px' }} /> Send me an email
        </button>
        <div className="social-media-links-About">
          <a href="https://github.com/jamalabdi2" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGithub} className="icon" />
          </a>
          <a href="https://twitter.com/juabji23" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} className="icon" />
          </a>
          <a href="https://www.linkedin.com/in/abdi-jumale-445010252/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} className="icon" />
          </a>
        </div>
      </div>
      <Skills />
      <Projects />
      <Connect/>
      <Footer/>
    </div>
  );
}

export default About;
