import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from './Modal';
import {LOGOUT_API_URL} from '../Config/Environmental_variables'
import {getJWTToken} from '../utils/useAuth'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Logout() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const token = getJWTToken()
  const handleLogout = async () => {
    setIsOpen(true);
  };

  const confirmLogout = async() => {
    setIsOpen(false);
    try {
      const response = await fetch(LOGOUT_API_URL, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Authorization': 'Bearer ' + token

        } 
      });

      if (response.ok) {
        navigate('/login');
      }else{
        const data = await response.json()
        toast.error(data.message)
      }
    } catch (error) {
      toast.error(error.message)
      
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <button onClick={handleLogout}>Logout</button>
      <Modal isOpen={isOpen} onClose={closeModal} onConfirm={confirmLogout} />
    </div>
  );
}

export default Logout;
